<template>
  <!-- <a-modal
      v-model="visible"
      :title="title"
      class="create-code--modal"
      @ok="handleSubmit"
      @cancel="handleCancel"
      :ok-button-props="{ props: { loading: loading } }"
    > -->
    <a-modal
      v-model="visible"
      :title="title"
      class="create-code--modal"
      :ok-button-props="{ props: { loading: loading } }"
      footer=''
    >
      <a-form :form="form" v-if="data">
        <a-form-item label="Code">
            <a-row :gutter="8">
              <a-col :span="20">
                <a-input
                  :disabled="checkDisabledCodeInput"
                  :max-length="10"
                  placeholder="Enter code"
                  v-decorator="[
                    'data.code',
                    {
                      initialValue: data.code,
                      rules: [
                        { required: true, message: 'Code is required!' },
                        { min: 4, message: 'At least 4 characters' },
                      ]
                    }
                  ]"
                >
                </a-input>
              </a-col>
              <a-col :span="4">
                <a-button :disabled="checkDisabledCodeInput" @click="generateCode" class="generate-code--btn">
                  Generate
                </a-button>
              </a-col>
            </a-row>
        </a-form-item>
        <a-form-item label="Limit">
          <a-input
            type="number"
            :max-length="10"
            placeholder="1000"
            v-decorator="[
              'data.limit',
              {
                initialValue: data.limit,
                rules: [
                  { required: true, message: 'Limit is required!' },
                ]
              }
            ]"
          />
        </a-form-item>
      </a-form>
      <hr>
      <div class="d-flex">
        <a-col class="text-left" :span="12">
          <CustomButton @click="handleCancel">
            Cancel
          </CustomButton>
        </a-col>
        <a-col class="text-right" :span="12">
          <a-button type="primary" @click="handleSubmit">
            Add
          </a-button>
        </a-col>
      </div>
    </a-modal>
</template>
<script>
/* eslint-disable */
import apiClient from '@/services/axios'
import CustomButton from '@/components/custom/button/CustomButton'

export default {
  props: {
    code: {
      type: Object,
    },
  },
  components: {
    CustomButton,
  },
  data() {
    return {
      data: null,
      visible: false,
      form: this.$form.createForm(this, {
        name: 'code',
      }),
      loading: false,
      title: "Create new code",
    }
  },
  computed: {
    checkDisabledCodeInput() {
      if (this.data.id) {
        return true;
      }
      return false;
    }
  },
  methods: {
    generateCode() {
      function makeid(length) {
          var result           = '';
          var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *  charactersLength));
        }
        return result;
      }
      const code = makeid(5);
      this.form.resetFields(['data.code']);
      this.data.code = code;
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.code.id) {
            await this.createNewCode({
              code: values.data.code,
              limit: values.data.limit,
            })
            return false
          }
          await this.editEvent({
            code: values.data.code,
            limit: values.data.limit,
          })
        }
      })
    },
    async createNewCode(payload) {
      try {
        this.loading = true
        await apiClient.post('admin/affiliates/store', { ...payload })
        this.$notification.success({
          message: 'Success',
          description: 'Code created successfully',
          duration: 2,
        });
        this.$emit('update');
        this.$emit('cancel');
        this.visible = false;
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async editEvent(payload) {
      try {
        this.loading = true
        await apiClient.patch(`admin/affiliates/${this.data.id}`, { ...payload })
        this.$notification.success({
          message: 'Success',
          description: 'Code edited successfully',
          duration: 2,
        })
        this.$emit('update')
        this.$emit('cancel')
        this.visible = false
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    showModal() {
      this.loading = false
      this.data = {...this.code};
      this.form.resetFields()
      this.visible = true
    },
    handleCancel() {
      this.$emit('cancel');
      this.form.resetFields();
      this.visible = false;
    },
  },
}
</script>

<style lang="scss">
.create-code--modal {
  .generate-code--btn {
    padding: 0px;
    width: 100%;
  }
}
</style>
