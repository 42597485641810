var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"create-code--modal",attrs:{"title":_vm.title,"ok-button-props":{ props: { loading: _vm.loading } },"footer":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.data)?_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Code"}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":20}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'data.code',
                  {
                    initialValue: _vm.data.code,
                    rules: [
                      { required: true, message: 'Code is required!' },
                      { min: 4, message: 'At least 4 characters' },
                    ]
                  }
                ]),expression:"[\n                  'data.code',\n                  {\n                    initialValue: data.code,\n                    rules: [\n                      { required: true, message: 'Code is required!' },\n                      { min: 4, message: 'At least 4 characters' },\n                    ]\n                  }\n                ]"}],attrs:{"disabled":_vm.checkDisabledCodeInput,"max-length":10,"placeholder":"Enter code"}})],1),_c('a-col',{attrs:{"span":4}},[_c('a-button',{staticClass:"generate-code--btn",attrs:{"disabled":_vm.checkDisabledCodeInput},on:{"click":_vm.generateCode}},[_vm._v(" Generate ")])],1)],1)],1),_c('a-form-item',{attrs:{"label":"Limit"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'data.limit',
            {
              initialValue: _vm.data.limit,
              rules: [
                { required: true, message: 'Limit is required!' },
              ]
            }
          ]),expression:"[\n            'data.limit',\n            {\n              initialValue: data.limit,\n              rules: [\n                { required: true, message: 'Limit is required!' },\n              ]\n            }\n          ]"}],attrs:{"type":"number","max-length":10,"placeholder":"1000"}})],1)],1):_vm._e(),_c('hr'),_c('div',{staticClass:"d-flex"},[_c('a-col',{staticClass:"text-left",attrs:{"span":12}},[_c('CustomButton',{on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")])],1),_c('a-col',{staticClass:"text-right",attrs:{"span":12}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Add ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }